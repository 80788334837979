const ROUTES = {
  "/": {
    "name": "HomePageB597a32841ab446586d0C3622d531800",
    "type": "HTML",
    "key": "home-page"
  },
  "/_admin/*": {
    "name": "AdminPagesA94778b93c3242e680713bea3f53e080",
    "type": "HTML",
    "key": "admin-pages"
  },
  "/about": {
    "name": "AboutUsPageBc1b788107a8451288a4E38fb28b77ae",
    "type": "HTML",
    "key": "about-us-page"
  },
  "/about/leveling": {
    "name": "AboutLevelingPageD6e25bda2bb647e2Ad606e132933daae",
    "type": "HTML",
    "key": "about-leveling-page"
  },
  "/events/public": {
    "name": "PublicEventsPage76aed20bE5c24c9eB0ef4475633be628",
    "type": "HTML",
    "key": "public-events-page"
  },
  "/join": {
    "name": "JoinUsPage3ab5878bE76d461b875139b9d191b89a",
    "type": "REFERENCE",
    "key": "join-us-page"
  },
  "/members/*": {
    "name": "MemberPages2cc81005F0a848579d27Ce5c1e70b7c7",
    "type": "HTML",
    "key": "member-pages"
  },
  "/members/messages/*": {
    "name": "MessagePages967bd43dF2bd4215Af729057a6d8f74d",
    "type": "HTML",
    "key": "message-pages"
  },
  "/resources": {
    "name": "ResourcesPage63fda1b0Ff11451281c5D1000b04120a",
    "type": "REFERENCE",
    "key": "resources-page"
  },
  "/signup/*": {
    "name": "SignupPagesC3baae3aCb694c3fA27539ff768a5365",
    "type": "REFERENCE",
    "key": "signup-pages"
  }
};

export default ROUTES;