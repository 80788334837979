import AppStore from "./AppStore";
import { buildClassName } from "./util";

// id: "0a772718-e287-4403-999c-ec13d8119d0c"
// title: "Header Area Top"
// type: :html
// key: "default-header-top"
// parent_id: nil
export function DefaultHeaderTop0a772718E2874403999cEc13d8119d0c(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultHeaderTop0a772718E2874403999cEc13d8119d0c header-top`}>
      {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogo54d6de075e494d19A14b19aaea5a8b14", parentTag)} className="linked-logo" url="/" label={<DefaultLogo2c0aa295457a46b79ec1Ae1ffd38e917 parentTag="LabelB8931ee1F1614e3486e8884d96826432" alt="logo" />} {...props} />}
      {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation4402b0ceD19149a690cc67bbc6f830fb", parentTag)} navMenuSlug="marketing-primary-nav" className="navigation" {...props} />}
    </div>
  );
}

// id: "88dc3206-50b3-4052-bc34-554e68c8f165"
// title: "Footer"
// type: :html
// key: "default-footer"
// parent_id: nil
export function DefaultFooter88dc320650b34052Bc34554e68c8f165(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultFooter88dc320650b34052Bc34554e68c8f165 footer`}>
      {props["links"] || <Navigation parentTag={buildClassName("Links5ad2700626fa4a37Af30B601fb2a2ed8", parentTag)} navMenuSlug="footer-nav" {...props} />}
      <div className="logo-wrapper">
        {props["logo"] || <LogoE8f9d23aF5a348258e6046fe435243c5 />}
      </div>
      <div>
        ©️ 2024 New Culture Camper Profile Directory
      </div>
      {props["groupflow"] || <GroupFlowTag parentTag={buildClassName("GroupflowCcd1f6e8C6904c3a8f6fDa9896e18d98", parentTag)} {...props} />}
    </div>
  );
}

// id: "b597a328-41ab-4465-86d0-c3622d531800"
// title: "Home Page"
// type: :html
// key: "home-page"
// parent_id: nil
export function HomePageB597a32841ab446586d0C3622d531800(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageB597a32841ab446586d0C3622d531800 page`}>
      {props["header"] || <HomeHeaderC0f27cf75b2b456fA4dc6c7868c94eab parentTag="Header41584388A0504d0192c9022a0d07250a" {...props} />}
      {props["welcome-back"] || <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack69ce52aeCc5a44a7Ac3367f59b4c319f", parentTag)} {...props} />}
      {props["body"] || <MarketingBody3469e5da49df403288e597389c4dc504 parentTag="Body0078e92913ed43d18d997c4ecf174ffe" {...props} />}
      {props["footer"] || <DefaultFooter88dc320650b34052Bc34554e68c8f165 parentTag="Footer6358d567D6a847c7B0611bd3cdbee2d1" {...props} />}
    </div>
  );
}

// id: "2ec3ff16-c8de-40dd-b6ce-a0f4e5c6147d"
// title: "Marketing Cards"
// type: :gf_preset
// key: "marketing-cards"
// parent_id: nil
export function MarketingCards2ec3ff16C8de40ddB6ceA0f4e5c6147d(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards parentTag={buildClassName("MarketingCards2ec3ff16C8de40ddB6ceA0f4e5c6147d", parentTag)} postSlug="home-page-posts2" buttonClass="inline-block px-12 py-3 hover:brightness-110 transition duration" {...props} />
  );
}

// id: "3469e5da-49df-4032-88e5-97389c4dc504"
// title: "Marketing Cards Body"
// type: :html
// key: "marketing-body"
// parent_id: nil
export function MarketingBody3469e5da49df403288e597389c4dc504(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MarketingBody3469e5da49df403288e597389c4dc504 page-body`}>
      {props["marketingCards"] || <MarketingCards2ec3ff16C8de40ddB6ceA0f4e5c6147d parentTag="MarketingCards4b5d9f421cb44558Ac53B41821d7b398" postSlug="home-page-posts2" {...props} />}
    </div>
  );
}

// id: "c0f27cf7-5b2b-456f-a4dc-6c7868c94eab"
// title: "Home Header"
// type: :html
// key: "home-header"
// parent_id: nil
export function HomeHeaderC0f27cf75b2b456fA4dc6c7868c94eab(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomeHeaderC0f27cf75b2b456fA4dc6c7868c94eab site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTop0a772718E2874403999cEc13d8119d0c parentTag="HeaderTop85a81603Ea924efe8e2f890807d3dbc7" {...props} />}
      </div>
      {props["hero"] || <HomePageHero3583b7061f7444e483edB61f66eb0c1e parentTag="Hero1c0a436aA6da4cbf8b93F172b08d17dc" {...props} />}
    </div>
  );
}

// id: "5a55259f-8484-48bb-bfb9-13187ebdf414"
// title: "Title - Leveling"
// type: :text
// key: "title"
// parent_id: nil
export const Title5a55259f848448bbBfb913187ebdf414 = "Leveling";

// id: "4b5d9f42-1cb4-4558-ac53-b41821d7b398"
// title: ""
// type: :reference
// key: "marketingCards"
// parent_id: "3469e5da-49df-4032-88e5-97389c4dc504"
export function MarketingCards4b5d9f421cb44558Ac53B41821d7b398(props: any) {
  return (
    <MarketingCards2ec3ff16C8de40ddB6ceA0f4e5c6147d parentTag="MarketingCards4b5d9f421cb44558Ac53B41821d7b398" postSlug="home-page-posts2" {...props} />
  );
}

// id: "e5405e2e-1121-42c9-9c0a-917e26811661"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "2cc81005-f0a8-4857-9d27-ce5c1e70b7c7"
export function FooterE5405e2e112142c99c0a917e26811661(props: any) {
  return (
    <DefaultFooter88dc320650b34052Bc34554e68c8f165 parentTag="FooterE5405e2e112142c99c0a917e26811661" {...props} />
  );
}

// id: "54d6de07-5e49-4d19-a14b-19aaea5a8b14"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "0a772718-e287-4403-999c-ec13d8119d0c"
export function LinkedLogo54d6de075e494d19A14b19aaea5a8b14(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogo54d6de075e494d19A14b19aaea5a8b14", parentTag)} className="linked-logo" url="/" label={<DefaultLogo2c0aa295457a46b79ec1Ae1ffd38e917 parentTag="LabelB8931ee1F1614e3486e8884d96826432" alt="logo" />} {...props} />
  );
}

// id: "295c317a-bef5-4621-a471-3ffd70043efe"
// title: ""
// type: :reference
// key: "header"
// parent_id: "c3baae3a-cb69-4c3f-a275-39ff768a5365"
export function Header295c317aBef54621A4713ffd70043efe(props: any) {
  return (
    <SecondaryMarketingHeader701a21354900449a93c4Decdba9c310a parentTag="Header295c317aBef54621A4713ffd70043efe" title="Join Us" {...props} />
  );
}

// id: "4c5d5bfa-6ba5-41b4-9cd9-15ad8025d482"
// title: ""
// type: :reference
// key: "header"
// parent_id: "63fda1b0-ff11-4512-81c5-d1000b04120a"
export function Header4c5d5bfa6ba541b49cd915ad8025d482(props: any) {
  return (
    <SecondaryMarketingHeader701a21354900449a93c4Decdba9c310a parentTag="Header4c5d5bfa6ba541b49cd915ad8025d482" title="Resources" {...props} />
  );
}

// id: "ac5f58b7-8a53-4669-a6ae-65ea5c02a691"
// title: ""
// type: :text
// key: "href"
// parent_id: "2110a5cb-9d7a-4b85-9eb9-7e3de59b5e6c"
export const HrefAc5f58b78a534669A6ae65ea5c02a691 = "/join";

// id: "f405d6e8-35f1-46a7-9227-fd29d5ad8c1f"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "5ad27006-26fa-4a37-af30-b601fb2a2ed8"
export const NavMenuSlugF405d6e835f146a79227Fd29d5ad8c1f = "footer-nav";

// id: "c61cebd4-a4f9-49e2-9e2a-ec1da9dda723"
// title: "Secondary Page"
// type: :html
// key: "secondary-page"
// parent_id: nil
export function SecondaryPageC61cebd4A4f949e29e2aEc1da9dda723(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryPageC61cebd4A4f949e29e2aEc1da9dda723`}>
      {props["header"] || <SecondaryMarketingHeader701a21354900449a93c4Decdba9c310a parentTag="Header269dbc8f1de9400eA83f244b064ec801" title="Secondary Page" {...props} />}
      <div className="text-content">
        {props.children}
      </div>
    </div>
  );
}

// id: "f8b6e473-0535-4655-b815-9f4f936a246b"
// title: "Common Footer"
// type: :reference
// key: "footer"
// parent_id: nil
export function FooterF8b6e47305354655B8159f4f936a246b(props: any) {
  return (
    <DefaultFooter88dc320650b34052Bc34554e68c8f165 parentTag="FooterF8b6e47305354655B8159f4f936a246b" {...props} />
  );
}

// id: "69ce52ae-cc5a-44a7-ac33-67f59b4c319f"
// title: ""
// type: :gf_preset
// key: "welcome-back"
// parent_id: "b597a328-41ab-4465-86d0-c3622d531800"
export function WelcomeBack69ce52aeCc5a44a7Ac3367f59b4c319f(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack69ce52aeCc5a44a7Ac3367f59b4c319f", parentTag)} {...props} />
  );
}

// id: "3940f80f-aacf-43ca-8e05-3561c153fa28"
// title: "Marketing Primary Navigation"
// type: :text
// key: "navMenuSlug"
// parent_id: "b06320de-3878-4a95-8644-2ad30b4f0b62"
export const NavMenuSlug3940f80fAacf43ca8e053561c153fa28 = "marketing-primary-nav";

// id: "3d47a2ae-ff69-4276-8146-e4b25bf442d8"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "701a2135-4900-449a-93c4-decdba9c310a"
export function HeaderTop3d47a2aeFf6942768146E4b25bf442d8(props: any) {
  return (
    <DefaultHeaderTop0a772718E2874403999cEc13d8119d0c parentTag="HeaderTop3d47a2aeFf6942768146E4b25bf442d8" {...props} />
  );
}

// id: "f1532cec-cb75-4f0e-827c-81607b1f7f96"
// title: ""
// type: :reference
// key: "header"
// parent_id: "2cc81005-f0a8-4857-9d27-ce5c1e70b7c7"
export function HeaderF1532cecCb754f0e827c81607b1f7f96(props: any) {
  return (
    <MembersAreaHeader39c734ed73a64e11BddcC5ae3359763e parentTag="HeaderF1532cecCb754f0e827c81607b1f7f96" {...props} />
  );
}

// id: "ad46c0a9-b77c-4c0a-99e5-19f2506c305c"
// title: ""
// type: :reference
// key: "header"
// parent_id: "d6e25bda-2bb6-47e2-ad60-6e132933daae"
export function HeaderAd46c0a9B77c4c0a99e519f2506c305c(props: any) {
  return (
    <SecondaryMarketingHeader701a21354900449a93c4Decdba9c310a parentTag="HeaderAd46c0a9B77c4c0a99e519f2506c305c" title="Leveling" {...props} />
  );
}

// id: "5228cd40-288a-441b-b4f3-d6485d9b27fe"
// title: ""
// type: :text
// key: "class"
// parent_id: "54d6de07-5e49-4d19-a14b-19aaea5a8b14"
export const Class5228cd40288a441bB4f3D6485d9b27fe = "linked-logo";

// id: "d5f552e2-0908-4e65-b46c-5b7a01af8b98"
// title: ""
// type: :text
// key: "title"
// parent_id: "ad46c0a9-b77c-4c0a-99e5-19f2506c305c"
export const TitleD5f552e209084e65B46c5b7a01af8b98 = "Leveling";

// id: "b93f5a6a-9d0a-4098-b15e-64cd72752fa7"
// title: "Members Area"
// type: :html
// key: "members-area-default"
// parent_id: nil
export function MembersAreaDefaultB93f5a6a9d0a4098B15e64cd72752fa7(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MembersAreaDefaultB93f5a6a9d0a4098B15e64cd72752fa7 page`}>
      {props["header"] || <MembersAreaHeader39c734ed73a64e11BddcC5ae3359763e parentTag="HeaderBc359042738b40dd83f6A3e0ddc6e8d9" navMenuSlug="members-primary-nav" {...props} />}
      {props["footer"] || <DefaultFooter88dc320650b34052Bc34554e68c8f165 parentTag="FooterD840387dDfae494f925e03ca7f2fb70a" {...props} />}
    </div>
  );
}

// id: "3ab5878b-e76d-461b-8751-39b9d191b89a"
// title: "Join Us Page"
// type: :reference
// key: "join-us-page"
// parent_id: nil
export function JoinUsPage3ab5878bE76d461b875139b9d191b89a(props: any) {
  return (
    <SecondaryPageC61cebd4A4f949e29e2aEc1da9dda723 parentTag="JoinUsPage3ab5878bE76d461b875139b9d191b89a" header={<SecondaryMarketingHeader701a21354900449a93c4Decdba9c310a parentTag="Header392ff65d54cc42e4A27669772b61b91e" title="Join Us" />} {...props} />
  );
}

// id: "1a758ee7-76ce-42bb-8d2a-e347c415009e"
// title: "Members Header"
// type: :reference
// key: "header"
// parent_id: nil
export function Header1a758ee776ce42bb8d2aE347c415009e(props: any) {
  return (
    <MembersAreaHeader39c734ed73a64e11BddcC5ae3359763e parentTag="Header1a758ee776ce42bb8d2aE347c415009e" {...props} />
  );
}

// id: "3e10e56c-ce7d-46de-bdf6-d743baee543a"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "93970b6e-1912-4770-98cb-56eb7522ef50"
export const BackgroundImage3e10e56cCe7d46deBdf6D743baee543a = "https://mp1md-pub.s3.amazonaws.com/orgs/new-culture/alexander-grey-Jv_oD5CuVfw-unsplash%20orig.jpg";

// id: "e8f9d23a-f5a3-4825-8e60-46fe435243c5"
// title: ""
// type: :html
// key: "logo"
// parent_id: "88dc3206-50b3-4052-bc34-554e68c8f165"
export function LogoE8f9d23aF5a348258e6046fe435243c5(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/new-culture/logo-wide-white.svg" alt="logo" className={`${parentTag || ""} LogoE8f9d23aF5a348258e6046fe435243c5 logo`} />
  );
}

// id: "0689ce2e-9edf-48a2-a4b9-b5be03fa9cd9"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "93970b6e-1912-4770-98cb-56eb7522ef50"
export function LinkedLogo0689ce2e9edf48a2A4b9B5be03fa9cd9(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogo0689ce2e9edf48a2A4b9B5be03fa9cd9", parentTag)} label={<DefaultLogo2c0aa295457a46b79ec1Ae1ffd38e917 parentTag="Label13dfd47389c84c15882aBce8b0311d5f" alt="logo" />} {...props} />
  );
}

// id: "31631901-019b-4633-87f4-70d4a3471dd0"
// title: ""
// type: :text
// key: "title"
// parent_id: "918e1340-fc8a-4212-895d-ae86e46718e9"
export const Title31631901019b463387f470d4a3471dd0 = "Default Title";

// id: "29bd06e2-2f63-48ef-8eee-10f6c5fe8f27"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "0a32f2b8-5a3a-433f-bd5f-aedc55b21c7b"
export const NavMenuSlug29bd06e22f6348ef8eee10f6c5fe8f27 = "members-primary-nav";

// id: "fe8757d3-f152-48a3-b6a7-7c7e1605d1ef"
// title: ""
// type: :text
// key: "title"
// parent_id: "269dbc8f-1de9-400e-a83f-244b064ec801"
export const TitleFe8757d3F15248a3B6a77c7e1605d1ef = "Secondary Page";

// id: "83661175-29ce-44c1-a236-909be61742af"
// title: ""
// type: :text
// key: "title"
// parent_id: "64289aef-8eeb-47d7-a5e2-a61c2eda40d8"
export const Title8366117529ce44c1A236909be61742af = "About Us";

// id: "a94778b9-3c32-42e6-8071-3bea3f53e080"
// title: "Admin Pages"
// type: :html
// key: "admin-pages"
// parent_id: nil
export function AdminPagesA94778b93c3242e680713bea3f53e080(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AdminPagesA94778b93c3242e680713bea3f53e080`}>
      {props.children}
    </div>
  );
}

// id: "81994fbd-0da8-44e3-94ae-b554f240a49f"
// title: "Label"
// type: :reference
// key: "label"
// parent_id: nil
export function Label81994fbd0da844e394aeB554f240a49f(props: any) {
  return (
    <DefaultLogo2c0aa295457a46b79ec1Ae1ffd38e917 parentTag="Label81994fbd0da844e394aeB554f240a49f" {...props} />
  );
}

// id: "d840387d-dfae-494f-925e-03ca7f2fb70a"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "b93f5a6a-9d0a-4098-b15e-64cd72752fa7"
export function FooterD840387dDfae494f925e03ca7f2fb70a(props: any) {
  return (
    <DefaultFooter88dc320650b34052Bc34554e68c8f165 parentTag="FooterD840387dDfae494f925e03ca7f2fb70a" {...props} />
  );
}

// id: "b79a15ce-b5da-4659-aea2-a8b721c426fc"
// title: ""
// type: :gf_preset
// key: "quote"
// parent_id: "b597a328-41ab-4465-86d0-c3622d531800"
export function QuoteB79a15ceB5da4659Aea2A8b721c426fc(props: any) {
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PageQuote parentTag={buildClassName("QuoteB79a15ceB5da4659Aea2A8b721c426fc", parentTag)} contentSlug="home-page-quote" {...props} />
  );
}

// id: "f62f63c7-aed8-4b3a-a026-634378e58de2"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "c61cebd4-a4f9-49e2-9e2a-ec1da9dda723"
export function FooterF62f63c7Aed84b3aA026634378e58de2(props: any) {
  return (
    <DefaultFooter88dc320650b34052Bc34554e68c8f165 parentTag="FooterF62f63c7Aed84b3aA026634378e58de2" {...props} />
  );
}

// id: "36319111-de0d-4cd9-ad3c-56d827438c70"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "d6e25bda-2bb6-47e2-ad60-6e132933daae"
export function Footer36319111De0d4cd9Ad3c56d827438c70(props: any) {
  return (
    <DefaultFooter88dc320650b34052Bc34554e68c8f165 parentTag="Footer36319111De0d4cd9Ad3c56d827438c70" {...props} />
  );
}

// id: "1266bf1e-29a4-44bd-841f-bc80b84c2b77"
// title: ""
// type: :text
// key: "url"
// parent_id: "54d6de07-5e49-4d19-a14b-19aaea5a8b14"
export const Url1266bf1e29a444bd841fBc80b84c2b77 = "/";

// id: "c271f8df-3e06-46f2-90d9-d0dcc3218b51"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "6d3390cc-3d5a-4618-b0e0-16b1a338c071"
export const NavMenuSlugC271f8df3e0646f290d9D0dcc3218b51 = "members-primary-nav";

// id: "f1e3aa36-8df1-48bc-845b-24932f7e8ac6"
// title: "Page Quote"
// type: :html
// key: "page-quote"
// parent_id: nil
export function PageQuoteF1e3aa368df148bc845b24932f7e8ac6(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PageQuoteF1e3aa368df148bc845b24932f7e8ac6`}>
      <div>
      </div>
      <div>
      </div>
    </div>
  );
}

// id: "c3baae3a-cb69-4c3f-a275-39ff768a5365"
// title: "Signup Pages"
// type: :reference
// key: "signup-pages"
// parent_id: nil
export function SignupPagesC3baae3aCb694c3fA27539ff768a5365(props: any) {
  return (
    <SecondaryPageC61cebd4A4f949e29e2aEc1da9dda723 parentTag="SignupPagesC3baae3aCb694c3fA27539ff768a5365" header={<SecondaryMarketingHeader701a21354900449a93c4Decdba9c310a parentTag="Header295c317aBef54621A4713ffd70043efe" title="Join Us" />} {...props} />
  );
}

// id: "76aed20b-e5c2-4c9e-b0ef-4475633be628"
// title: "Public Events Page"
// type: :html
// key: "public-events-page"
// parent_id: nil
export function PublicEventsPage76aed20bE5c24c9eB0ef4475633be628(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PublicEventsPage76aed20bE5c24c9eB0ef4475633be628`}>
      {props["header"] || <SecondaryMarketingHeader701a21354900449a93c4Decdba9c310a parentTag="Header48beeecaCde04e8e928916ddc17ce180" title="Public Events" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter88dc320650b34052Bc34554e68c8f165 parentTag="Footer582f1991752c4195B317297932f49c30" {...props} />}
    </div>
  );
}

// id: "1c0a436a-a6da-4cbf-8b93-f172b08d17dc"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "c0f27cf7-5b2b-456f-a4dc-6c7868c94eab"
export function Hero1c0a436aA6da4cbf8b93F172b08d17dc(props: any) {
  return (
    <HomePageHero3583b7061f7444e483edB61f66eb0c1e parentTag="Hero1c0a436aA6da4cbf8b93F172b08d17dc" {...props} />
  );
}

// id: "744b01bc-4c8f-44a2-bf35-19c285e7b085"
// title: ""
// type: :gf_preset
// key: "secondary-nav"
// parent_id: "2cc81005-f0a8-4857-9d27-ce5c1e70b7c7"
export function SecondaryNav744b01bc4c8f44a2Bf3519c285e7b085(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <SecondaryNavigation parentTag={buildClassName("SecondaryNav744b01bc4c8f44a2Bf3519c285e7b085", parentTag)} {...props} />
  );
}

// id: "392ff65d-54cc-42e4-a276-69772b61b91e"
// title: ""
// type: :reference
// key: "header"
// parent_id: "3ab5878b-e76d-461b-8751-39b9d191b89a"
export function Header392ff65d54cc42e4A27669772b61b91e(props: any) {
  return (
    <SecondaryMarketingHeader701a21354900449a93c4Decdba9c310a parentTag="Header392ff65d54cc42e4A27669772b61b91e" title="Join Us" {...props} />
  );
}

// id: "269dbc8f-1de9-400e-a83f-244b064ec801"
// title: ""
// type: :reference
// key: "header"
// parent_id: "c61cebd4-a4f9-49e2-9e2a-ec1da9dda723"
export function Header269dbc8f1de9400eA83f244b064ec801(props: any) {
  return (
    <SecondaryMarketingHeader701a21354900449a93c4Decdba9c310a parentTag="Header269dbc8f1de9400eA83f244b064ec801" title="Secondary Page" {...props} />
  );
}

// id: "101829e0-601b-4f7d-86ad-2cdce9aa8930"
// title: ""
// type: :text
// key: "title"
// parent_id: "701a2135-4900-449a-93c4-decdba9c310a"
export const Title101829e0601b4f7d86ad2cdce9aa8930 = "Secondary Marketing Title 1";

// id: "e7589a66-fee8-427e-8ffe-68fab5564d3c"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "bc359042-738b-40dd-83f6-a3e0ddc6e8d9"
export const NavMenuSlugE7589a66Fee8427e8ffe68fab5564d3c = "members-primary-nav";

// id: "1cf3c82e-7ed7-414a-ac1b-ef93ece46d47"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "e3ebb18a-e419-42ec-8ea8-ca37d83aed00"
export const PostSlug1cf3c82e7ed7414aAc1bEf93ece46d47 = "about-us-posts";

// id: "b06320de-3878-4a95-8644-2ad30b4f0b62"
// title: "Default Header Reference"
// type: :reference
// key: "default-header"
// parent_id: nil
export function DefaultHeaderB06320de38784a9586442ad30b4f0b62(props: any) {
  return (
    <ShortHeader93970b6e1912477098cb56eb7522ef50 parentTag="DefaultHeaderB06320de38784a9586442ad30b4f0b62" navMenuSlug="marketing-primary-nav" title {...props} />
  );
}

// id: "cce33ba0-7664-4ad2-a7cd-d70a897897ab"
// title: "HREF"
// type: :text
// key: "href"
// parent_id: nil
export const HrefCce33ba076644ad2A7cdD70a897897ab = "/";

// id: "5ad27006-26fa-4a37-af30-b601fb2a2ed8"
// title: ""
// type: :gf_preset
// key: "links"
// parent_id: "88dc3206-50b3-4052-bc34-554e68c8f165"
export function Links5ad2700626fa4a37Af30B601fb2a2ed8(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Navigation parentTag={buildClassName("Links5ad2700626fa4a37Af30B601fb2a2ed8", parentTag)} navMenuSlug="footer-nav" {...props} />
  );
}

// id: "ad6a39c9-c462-4aaa-bee3-161b1e5c7998"
// title: ""
// type: :reference
// key: "header"
// parent_id: "967bd43d-f2bd-4215-af72-9057a6d8f74d"
export function HeaderAd6a39c9C4624aaaBee3161b1e5c7998(props: any) {
  return (
    <MembersAreaHeader39c734ed73a64e11BddcC5ae3359763e parentTag="HeaderAd6a39c9C4624aaaBee3161b1e5c7998" {...props} />
  );
}

// id: "2110a5cb-9d7a-4b85-9eb9-7e3de59b5e6c"
// title: ""
// type: :gf_preset
// key: "button"
// parent_id: "3583b706-1f74-44e4-83ed-b61f66eb0c1e"
export function Button2110a5cb9d7a4b859eb97e3de59b5e6c(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Button parentTag={buildClassName("Button2110a5cb9d7a4b859eb97e3de59b5e6c", parentTag)} href="/join" label="Join Us" {...props} />
  );
}

// id: "85a81603-ea92-4efe-8e2f-890807d3dbc7"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "c0f27cf7-5b2b-456f-a4dc-6c7868c94eab"
export function HeaderTop85a81603Ea924efe8e2f890807d3dbc7(props: any) {
  return (
    <DefaultHeaderTop0a772718E2874403999cEc13d8119d0c parentTag="HeaderTop85a81603Ea924efe8e2f890807d3dbc7" {...props} />
  );
}

// id: "8337a549-9209-4ae4-b136-cfa699c8b934"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "2ec3ff16-c8de-40dd-b6ce-a0f4e5c6147d"
export const PostSlug8337a54992094ae4B136Cfa699c8b934 = "home-page-posts2";

// id: "b8931ee1-f161-4e34-86e8-884d96826432"
// title: ""
// type: :reference
// key: "label"
// parent_id: "54d6de07-5e49-4d19-a14b-19aaea5a8b14"
export function LabelB8931ee1F1614e3486e8884d96826432(props: any) {
  return (
    <DefaultLogo2c0aa295457a46b79ec1Ae1ffd38e917 parentTag="LabelB8931ee1F1614e3486e8884d96826432" {...props} />
  );
}

// id: "d4ded0cf-bb94-4f0b-aeae-beefe3b81cda"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "4402b0ce-d191-49a6-90cc-67bbc6f830fb"
export const NavMenuSlugD4ded0cfBb944f0bAeaeBeefe3b81cda = "marketing-primary-nav";

// id: "2c0aa295-457a-46b7-9ec1-ae1ffd38e917"
// title: "Logo"
// type: :html
// key: "default-logo"
// parent_id: nil
export function DefaultLogo2c0aa295457a46b79ec1Ae1ffd38e917(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/BTqIz3e7Nstwfg7w_file.svg" className={`${parentTag || ""} DefaultLogo2c0aa295457a46b79ec1Ae1ffd38e917 logo`} alt="logo" />
  );
}

// id: "2cc81005-f0a8-4857-9d27-ce5c1e70b7c7"
// title: "Member Pages"
// type: :html
// key: "member-pages"
// parent_id: nil
export function MemberPages2cc81005F0a848579d27Ce5c1e70b7c7(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MemberPages2cc81005F0a848579d27Ce5c1e70b7c7`}>
      {props["header"] || <MembersAreaHeader39c734ed73a64e11BddcC5ae3359763e parentTag="HeaderF1532cecCb754f0e827c81607b1f7f96" {...props} />}
      {props["secondary-nav"] || <SecondaryNavigation parentTag={buildClassName("SecondaryNav744b01bc4c8f44a2Bf3519c285e7b085", parentTag)} {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter88dc320650b34052Bc34554e68c8f165 parentTag="FooterE5405e2e112142c99c0a917e26811661" {...props} />}
    </div>
  );
}

// id: "6d3390cc-3d5a-4618-b0e0-16b1a338c071"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "93970b6e-1912-4770-98cb-56eb7522ef50"
export function Navigation6d3390cc3d5a4618B0e016b1a338c071(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation6d3390cc3d5a4618B0e016b1a338c071", parentTag)} navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "ea993228-34a4-4b37-b509-eb648eced78d"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "bc1b7881-07a8-4512-88a4-e38fb28b77ae"
export function FooterEa99322834a44b37B509Eb648eced78d(props: any) {
  return (
    <DefaultFooter88dc320650b34052Bc34554e68c8f165 parentTag="FooterEa99322834a44b37B509Eb648eced78d" {...props} />
  );
}

// id: "0eefbec3-0352-42ee-ada1-7d097f88cd04"
// title: ""
// type: :gf_preset
// key: "copyright"
// parent_id: "88dc3206-50b3-4052-bc34-554e68c8f165"
export function Copyright0eefbec3035242eeAda17d097f88cd04(props: any) {
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupCopyright parentTag={buildClassName("Copyright0eefbec3035242eeAda17d097f88cd04", parentTag)} {...props} />
  );
}

// id: "582f1991-752c-4195-b317-297932f49c30"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "76aed20b-e5c2-4c9e-b0ef-4475633be628"
export function Footer582f1991752c4195B317297932f49c30(props: any) {
  return (
    <DefaultFooter88dc320650b34052Bc34554e68c8f165 parentTag="Footer582f1991752c4195B317297932f49c30" {...props} />
  );
}

// id: "41584388-a050-4d01-92c9-022a0d07250a"
// title: ""
// type: :reference
// key: "header"
// parent_id: "b597a328-41ab-4465-86d0-c3622d531800"
export function Header41584388A0504d0192c9022a0d07250a(props: any) {
  return (
    <HomeHeaderC0f27cf75b2b456fA4dc6c7868c94eab parentTag="Header41584388A0504d0192c9022a0d07250a" {...props} />
  );
}

// id: "f568db06-9ae0-45f2-92bc-0d36b492afd0"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "b79a15ce-b5da-4659-aea2-a8b721c426fc"
export const ContentSlugF568db069ae045f292bc0d36b492afd0 = "home-page-quote";

// id: "34be4db1-7e94-4e65-bddf-b9c22cf6416f"
// title: ""
// type: :text
// key: "title"
// parent_id: "b3af5e7d-07b1-4b9d-b5f2-37f550d230d6"
export const Title34be4db17e944e65BddfB9c22cf6416f = "Secondary Marketing Title 2";

// id: "624e2295-ba7a-4d9b-9902-e6e0cc23cedb"
// title: ""
// type: :text
// key: "title"
// parent_id: "4c5d5bfa-6ba5-41b4-9cd9-15ad8025d482"
export const Title624e2295Ba7a4d9b9902E6e0cc23cedb = "Resources";

// id: "bc1b7881-07a8-4512-88a4-e38fb28b77ae"
// title: "About Us"
// type: :html
// key: "about-us-page"
// parent_id: nil
export function AboutUsPageBc1b788107a8451288a4E38fb28b77ae(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutUsPageBc1b788107a8451288a4E38fb28b77ae page`}>
      {props["header"] || <SecondaryMarketingHeader701a21354900449a93c4Decdba9c310a parentTag="Header64289aef8eeb47d7A5e2A61c2eda40d8" title="About Us" {...props} />}
      {props["body"] || <MarketingBody3469e5da49df403288e597389c4dc504 parentTag="BodyE3ebb18aE41942ec8ea8Ca37d83aed00" postSlug="about-us-posts" {...props} />}
      {props["footer"] || <DefaultFooter88dc320650b34052Bc34554e68c8f165 parentTag="FooterEa99322834a44b37B509Eb648eced78d" {...props} />}
    </div>
  );
}

// id: "967bd43d-f2bd-4215-af72-9057a6d8f74d"
// title: "Message Pages"
// type: :html
// key: "message-pages"
// parent_id: nil
export function MessagePages967bd43dF2bd4215Af729057a6d8f74d(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MessagePages967bd43dF2bd4215Af729057a6d8f74d`}>
      {props["header"] || <MembersAreaHeader39c734ed73a64e11BddcC5ae3359763e parentTag="HeaderAd6a39c9C4624aaaBee3161b1e5c7998" {...props} />}
      {props.children}
    </div>
  );
}

// id: "4402b0ce-d191-49a6-90cc-67bbc6f830fb"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "0a772718-e287-4403-999c-ec13d8119d0c"
export function Navigation4402b0ceD19149a690cc67bbc6f830fb(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation4402b0ceD19149a690cc67bbc6f830fb", parentTag)} navMenuSlug="marketing-primary-nav" className="navigation" {...props} />
  );
}

// id: "b3af5e7d-07b1-4b9d-b5f2-37f550d230d6"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "701a2135-4900-449a-93c4-decdba9c310a"
export function HeroB3af5e7d07b14b9dB5f237f550d230d6(props: any) {
  return (
    <SecondaryMarketingHero918e1340Fc8a4212895dAe86e46718e9 parentTag="HeroB3af5e7d07b14b9dB5f237f550d230d6" title="Secondary Marketing Title 2" {...props} />
  );
}

// id: "e3ebb18a-e419-42ec-8ea8-ca37d83aed00"
// title: ""
// type: :reference
// key: "body"
// parent_id: "bc1b7881-07a8-4512-88a4-e38fb28b77ae"
export function BodyE3ebb18aE41942ec8ea8Ca37d83aed00(props: any) {
  return (
    <MarketingBody3469e5da49df403288e597389c4dc504 parentTag="BodyE3ebb18aE41942ec8ea8Ca37d83aed00" postSlug="about-us-posts" {...props} />
  );
}

// id: "48beeeca-cde0-4e8e-9289-16ddc17ce180"
// title: ""
// type: :reference
// key: "header"
// parent_id: "76aed20b-e5c2-4c9e-b0ef-4475633be628"
export function Header48beeecaCde04e8e928916ddc17ce180(props: any) {
  return (
    <SecondaryMarketingHeader701a21354900449a93c4Decdba9c310a parentTag="Header48beeecaCde04e8e928916ddc17ce180" title="Public Events" {...props} />
  );
}

// id: "52274ba6-6b8a-475a-998e-f9d77aac29f7"
// title: ""
// type: :text
// key: "alt"
// parent_id: "2c0aa295-457a-46b7-9ec1-ae1ffd38e917"
export const Alt52274ba66b8a475a998eF9d77aac29f7 = "logo";

// id: "81a9446c-10ee-4878-8b02-fb07e8a39af7"
// title: ""
// type: :text
// key: "label"
// parent_id: "2110a5cb-9d7a-4b85-9eb9-7e3de59b5e6c"
export const Label81a9446c10ee48788b02Fb07e8a39af7 = "Join Us";

// id: "31541639-dbb4-4e4e-85ef-4ebf13ea8159"
// title: ""
// type: :text
// key: "title"
// parent_id: "295c317a-bef5-4621-a471-3ffd70043efe"
export const Title31541639Dbb44e4e85ef4ebf13ea8159 = "Join Us";

// id: "5bc60b70-6858-4327-b881-62a617ef243b"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "4b5d9f42-1cb4-4558-ac53-b41821d7b398"
export const PostSlug5bc60b7068584327B88162a617ef243b = "home-page-posts2";

// id: "63fda1b0-ff11-4512-81c5-d1000b04120a"
// title: "Resources Page"
// type: :reference
// key: "resources-page"
// parent_id: nil
export function ResourcesPage63fda1b0Ff11451281c5D1000b04120a(props: any) {
  return (
    <SecondaryPageC61cebd4A4f949e29e2aEc1da9dda723 parentTag="ResourcesPage63fda1b0Ff11451281c5D1000b04120a" header={<SecondaryMarketingHeader701a21354900449a93c4Decdba9c310a parentTag="Header4c5d5bfa6ba541b49cd915ad8025d482" title="Resources" />} {...props} />
  );
}

// id: "93970b6e-1912-4770-98cb-56eb7522ef50"
// title: "Short Header"
// type: :html
// key: "short-header"
// parent_id: nil
export function ShortHeader93970b6e1912477098cb56eb7522ef50(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} ShortHeader93970b6e1912477098cb56eb7522ef50 site-header`}>
      <div className="background-image" />
      <div className="header-top">
        {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogo0689ce2e9edf48a2A4b9B5be03fa9cd9", parentTag)} label={<DefaultLogo2c0aa295457a46b79ec1Ae1ffd38e917 parentTag="Label13dfd47389c84c15882aBce8b0311d5f" alt="logo" />} {...props} />}
        {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation6d3390cc3d5a4618B0e016b1a338c071", parentTag)} navMenuSlug="members-primary-nav" {...props} />}
      </div>
    </div>
  );
}

// id: "d6e25bda-2bb6-47e2-ad60-6e132933daae"
// title: "Levels 1-4 Explained"
// type: :html
// key: "about-leveling-page"
// parent_id: nil
export function AboutLevelingPageD6e25bda2bb647e2Ad606e132933daae(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutLevelingPageD6e25bda2bb647e2Ad606e132933daae`}>
      {props["header"] || <SecondaryMarketingHeader701a21354900449a93c4Decdba9c310a parentTag="HeaderAd46c0a9B77c4c0a99e519f2506c305c" title="Leveling" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter88dc320650b34052Bc34554e68c8f165 parentTag="Footer36319111De0d4cd9Ad3c56d827438c70" {...props} />}
    </div>
  );
}

// id: "22663c53-bc7f-48a7-8adb-19a14d4afb1b"
// title: ""
// type: :text
// key: "buttonClass"
// parent_id: "2ec3ff16-c8de-40dd-b6ce-a0f4e5c6147d"
export const ButtonClass22663c53Bc7f48a78adb19a14d4afb1b = "inline-block px-12 py-3 hover:brightness-110 transition duration";

// id: "0a32f2b8-5a3a-433f-bd5f-aedc55b21c7b"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "39c734ed-73a6-4e11-bddc-c5ae3359763e"
export function HeaderTop0a32f2b85a3a433fBd5fAedc55b21c7b(props: any) {
  return (
    <DefaultHeaderTop0a772718E2874403999cEc13d8119d0c parentTag="HeaderTop0a32f2b85a3a433fBd5fAedc55b21c7b" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "6358d567-d6a8-47c7-b061-1bd3cdbee2d1"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "b597a328-41ab-4465-86d0-c3622d531800"
export function Footer6358d567D6a847c7B0611bd3cdbee2d1(props: any) {
  return (
    <DefaultFooter88dc320650b34052Bc34554e68c8f165 parentTag="Footer6358d567D6a847c7B0611bd3cdbee2d1" {...props} />
  );
}

// id: "13dfd473-89c8-4c15-882a-bce8b0311d5f"
// title: ""
// type: :reference
// key: "label"
// parent_id: "0689ce2e-9edf-48a2-a4b9-b5be03fa9cd9"
export function Label13dfd47389c84c15882aBce8b0311d5f(props: any) {
  return (
    <DefaultLogo2c0aa295457a46b79ec1Ae1ffd38e917 parentTag="Label13dfd47389c84c15882aBce8b0311d5f" {...props} />
  );
}

// id: "995c9a79-c453-423b-be0e-54c3ceecbba0"
// title: ""
// type: :text
// key: "class"
// parent_id: "4402b0ce-d191-49a6-90cc-67bbc6f830fb"
export const Class995c9a79C453423bBe0e54c3ceecbba0 = "navigation";

// id: "39c734ed-73a6-4e11-bddc-c5ae3359763e"
// title: "Members Area Header"
// type: :reference
// key: "members-area-header"
// parent_id: nil
export function MembersAreaHeader39c734ed73a64e11BddcC5ae3359763e(props: any) {
  return (
    <ShortHeader93970b6e1912477098cb56eb7522ef50 parentTag="MembersAreaHeader39c734ed73a64e11BddcC5ae3359763e" header-top={<DefaultHeaderTop0a772718E2874403999cEc13d8119d0c parentTag="HeaderTop0a32f2b85a3a433fBd5fAedc55b21c7b" navMenuSlug="members-primary-nav" />} {...props} />
  );
}

// id: "918e1340-fc8a-4212-895d-ae86e46718e9"
// title: "Secondary Marketing Hero"
// type: :html
// key: "secondary-marketing-hero"
// parent_id: nil
export function SecondaryMarketingHero918e1340Fc8a4212895dAe86e46718e9(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHero918e1340Fc8a4212895dAe86e46718e9 hero`}>
      <div className="title">
        {props["title"] || Title31631901019b463387f470d4a3471dd0}
      </div>
    </div>
  );
}

// id: "ccd1f6e8-c690-4c3a-8f6f-da9896e18d98"
// title: ""
// type: :gf_preset
// key: "groupflow"
// parent_id: "88dc3206-50b3-4052-bc34-554e68c8f165"
export function GroupflowCcd1f6e8C6904c3a8f6fDa9896e18d98(props: any) {
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupFlowTag parentTag={buildClassName("GroupflowCcd1f6e8C6904c3a8f6fDa9896e18d98", parentTag)} {...props} />
  );
}

// id: "64289aef-8eeb-47d7-a5e2-a61c2eda40d8"
// title: ""
// type: :reference
// key: "header"
// parent_id: "bc1b7881-07a8-4512-88a4-e38fb28b77ae"
export function Header64289aef8eeb47d7A5e2A61c2eda40d8(props: any) {
  return (
    <SecondaryMarketingHeader701a21354900449a93c4Decdba9c310a parentTag="Header64289aef8eeb47d7A5e2A61c2eda40d8" title="About Us" {...props} />
  );
}

// id: "bc359042-738b-40dd-83f6-a3e0ddc6e8d9"
// title: ""
// type: :reference
// key: "header"
// parent_id: "b93f5a6a-9d0a-4098-b15e-64cd72752fa7"
export function HeaderBc359042738b40dd83f6A3e0ddc6e8d9(props: any) {
  return (
    <MembersAreaHeader39c734ed73a64e11BddcC5ae3359763e parentTag="HeaderBc359042738b40dd83f6A3e0ddc6e8d9" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "0078e929-13ed-43d1-8d99-7c4ecf174ffe"
// title: ""
// type: :reference
// key: "body"
// parent_id: "b597a328-41ab-4465-86d0-c3622d531800"
export function Body0078e92913ed43d18d997c4ecf174ffe(props: any) {
  return (
    <MarketingBody3469e5da49df403288e597389c4dc504 parentTag="Body0078e92913ed43d18d997c4ecf174ffe" {...props} />
  );
}

// id: "4e55c02f-5690-44db-9a7b-9605ede6d6b2"
// title: ""
// type: :text
// key: "title"
// parent_id: "b06320de-3878-4a95-8644-2ad30b4f0b62"
export const Title4e55c02f569044db9a7b9605ede6d6b2 = null;

// id: "2b9afeed-8031-41b7-8062-9b85487edefc"
// title: ""
// type: :text
// key: "title"
// parent_id: "392ff65d-54cc-42e4-a276-69772b61b91e"
export const Title2b9afeed803141b780629b85487edefc = "Join Us";

// id: "d0a25b45-2578-4b0a-b425-41f4bcbd947a"
// title: ""
// type: :text
// key: "title"
// parent_id: "48beeeca-cde0-4e8e-9289-16ddc17ce180"
export const TitleD0a25b4525784b0aB42541f4bcbd947a = "Public Events";

// id: "701a2135-4900-449a-93c4-decdba9c310a"
// title: "Secondary Marketing Header"
// type: :html
// key: "secondary-marketing-header"
// parent_id: nil
export function SecondaryMarketingHeader701a21354900449a93c4Decdba9c310a(props: any) {
  const { parentTag, ...rest } = props;
  props = { title: "Secondary Marketing Title 1", ...rest };
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeader701a21354900449a93c4Decdba9c310a site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTop0a772718E2874403999cEc13d8119d0c parentTag="HeaderTop3d47a2aeFf6942768146E4b25bf442d8" {...props} />}
      </div>
      {props["hero"] || <SecondaryMarketingHero918e1340Fc8a4212895dAe86e46718e9 parentTag="HeroB3af5e7d07b14b9dB5f237f550d230d6" title="Secondary Marketing Title 2" {...props} />}
    </div>
  );
}

// id: "3583b706-1f74-44e4-83ed-b61f66eb0c1e"
// title: "Home Page Hero"
// type: :html
// key: "home-page-hero"
// parent_id: nil
export function HomePageHero3583b7061f7444e483edB61f66eb0c1e(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageHero3583b7061f7444e483edB61f66eb0c1e hero`}>
      <div className="title">
        {props["title"] || <ContentSnippet parentTag={buildClassName("Title75d8e4575495444cA028F118b538c153", parentTag)} contentSlug="home-hero-title" {...props} />}
      </div>
      <div className="button">
        {props["button"] || <Button parentTag={buildClassName("Button2110a5cb9d7a4b859eb97e3de59b5e6c", parentTag)} href="/join" label="Join Us" {...props} />}
      </div>
    </div>
  );
}

// id: "75d8e457-5495-444c-a028-f118b538c153"
// title: ""
// type: :gf_preset
// key: "title"
// parent_id: "3583b706-1f74-44e4-83ed-b61f66eb0c1e"
export function Title75d8e4575495444cA028F118b538c153(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <ContentSnippet parentTag={buildClassName("Title75d8e4575495444cA028F118b538c153", parentTag)} contentSlug="home-hero-title" {...props} />
  );
}

// id: "4caf7e58-44f4-4244-9d52-2e16f7c8d923"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "75d8e457-5495-444c-a028-f118b538c153"
export const ContentSlug4caf7e5844f442449d522e16f7c8d923 = "home-hero-title";

const Components = {
  DefaultHeaderTop0a772718E2874403999cEc13d8119d0c,
  DefaultFooter88dc320650b34052Bc34554e68c8f165,
  HomePageB597a32841ab446586d0C3622d531800,
  MarketingCards2ec3ff16C8de40ddB6ceA0f4e5c6147d,
  MarketingBody3469e5da49df403288e597389c4dc504,
  HomeHeaderC0f27cf75b2b456fA4dc6c7868c94eab,
  Title5a55259f848448bbBfb913187ebdf414,
  MarketingCards4b5d9f421cb44558Ac53B41821d7b398,
  FooterE5405e2e112142c99c0a917e26811661,
  LinkedLogo54d6de075e494d19A14b19aaea5a8b14,
  Header295c317aBef54621A4713ffd70043efe,
  Header4c5d5bfa6ba541b49cd915ad8025d482,
  HrefAc5f58b78a534669A6ae65ea5c02a691,
  NavMenuSlugF405d6e835f146a79227Fd29d5ad8c1f,
  SecondaryPageC61cebd4A4f949e29e2aEc1da9dda723,
  FooterF8b6e47305354655B8159f4f936a246b,
  WelcomeBack69ce52aeCc5a44a7Ac3367f59b4c319f,
  NavMenuSlug3940f80fAacf43ca8e053561c153fa28,
  HeaderTop3d47a2aeFf6942768146E4b25bf442d8,
  HeaderF1532cecCb754f0e827c81607b1f7f96,
  HeaderAd46c0a9B77c4c0a99e519f2506c305c,
  Class5228cd40288a441bB4f3D6485d9b27fe,
  TitleD5f552e209084e65B46c5b7a01af8b98,
  MembersAreaDefaultB93f5a6a9d0a4098B15e64cd72752fa7,
  JoinUsPage3ab5878bE76d461b875139b9d191b89a,
  Header1a758ee776ce42bb8d2aE347c415009e,
  BackgroundImage3e10e56cCe7d46deBdf6D743baee543a,
  LogoE8f9d23aF5a348258e6046fe435243c5,
  LinkedLogo0689ce2e9edf48a2A4b9B5be03fa9cd9,
  Title31631901019b463387f470d4a3471dd0,
  NavMenuSlug29bd06e22f6348ef8eee10f6c5fe8f27,
  TitleFe8757d3F15248a3B6a77c7e1605d1ef,
  Title8366117529ce44c1A236909be61742af,
  AdminPagesA94778b93c3242e680713bea3f53e080,
  Label81994fbd0da844e394aeB554f240a49f,
  FooterD840387dDfae494f925e03ca7f2fb70a,
  QuoteB79a15ceB5da4659Aea2A8b721c426fc,
  FooterF62f63c7Aed84b3aA026634378e58de2,
  Footer36319111De0d4cd9Ad3c56d827438c70,
  Url1266bf1e29a444bd841fBc80b84c2b77,
  NavMenuSlugC271f8df3e0646f290d9D0dcc3218b51,
  PageQuoteF1e3aa368df148bc845b24932f7e8ac6,
  SignupPagesC3baae3aCb694c3fA27539ff768a5365,
  PublicEventsPage76aed20bE5c24c9eB0ef4475633be628,
  Hero1c0a436aA6da4cbf8b93F172b08d17dc,
  SecondaryNav744b01bc4c8f44a2Bf3519c285e7b085,
  Header392ff65d54cc42e4A27669772b61b91e,
  Header269dbc8f1de9400eA83f244b064ec801,
  Title101829e0601b4f7d86ad2cdce9aa8930,
  NavMenuSlugE7589a66Fee8427e8ffe68fab5564d3c,
  PostSlug1cf3c82e7ed7414aAc1bEf93ece46d47,
  DefaultHeaderB06320de38784a9586442ad30b4f0b62,
  HrefCce33ba076644ad2A7cdD70a897897ab,
  Links5ad2700626fa4a37Af30B601fb2a2ed8,
  HeaderAd6a39c9C4624aaaBee3161b1e5c7998,
  Button2110a5cb9d7a4b859eb97e3de59b5e6c,
  HeaderTop85a81603Ea924efe8e2f890807d3dbc7,
  PostSlug8337a54992094ae4B136Cfa699c8b934,
  LabelB8931ee1F1614e3486e8884d96826432,
  NavMenuSlugD4ded0cfBb944f0bAeaeBeefe3b81cda,
  DefaultLogo2c0aa295457a46b79ec1Ae1ffd38e917,
  MemberPages2cc81005F0a848579d27Ce5c1e70b7c7,
  Navigation6d3390cc3d5a4618B0e016b1a338c071,
  FooterEa99322834a44b37B509Eb648eced78d,
  Copyright0eefbec3035242eeAda17d097f88cd04,
  Footer582f1991752c4195B317297932f49c30,
  Header41584388A0504d0192c9022a0d07250a,
  ContentSlugF568db069ae045f292bc0d36b492afd0,
  Title34be4db17e944e65BddfB9c22cf6416f,
  Title624e2295Ba7a4d9b9902E6e0cc23cedb,
  AboutUsPageBc1b788107a8451288a4E38fb28b77ae,
  MessagePages967bd43dF2bd4215Af729057a6d8f74d,
  Navigation4402b0ceD19149a690cc67bbc6f830fb,
  HeroB3af5e7d07b14b9dB5f237f550d230d6,
  BodyE3ebb18aE41942ec8ea8Ca37d83aed00,
  Header48beeecaCde04e8e928916ddc17ce180,
  Alt52274ba66b8a475a998eF9d77aac29f7,
  Label81a9446c10ee48788b02Fb07e8a39af7,
  Title31541639Dbb44e4e85ef4ebf13ea8159,
  PostSlug5bc60b7068584327B88162a617ef243b,
  ResourcesPage63fda1b0Ff11451281c5D1000b04120a,
  ShortHeader93970b6e1912477098cb56eb7522ef50,
  AboutLevelingPageD6e25bda2bb647e2Ad606e132933daae,
  ButtonClass22663c53Bc7f48a78adb19a14d4afb1b,
  HeaderTop0a32f2b85a3a433fBd5fAedc55b21c7b,
  Footer6358d567D6a847c7B0611bd3cdbee2d1,
  Label13dfd47389c84c15882aBce8b0311d5f,
  Class995c9a79C453423bBe0e54c3ceecbba0,
  MembersAreaHeader39c734ed73a64e11BddcC5ae3359763e,
  SecondaryMarketingHero918e1340Fc8a4212895dAe86e46718e9,
  GroupflowCcd1f6e8C6904c3a8f6fDa9896e18d98,
  Header64289aef8eeb47d7A5e2A61c2eda40d8,
  HeaderBc359042738b40dd83f6A3e0ddc6e8d9,
  Body0078e92913ed43d18d997c4ecf174ffe,
  Title4e55c02f569044db9a7b9605ede6d6b2,
  Title2b9afeed803141b780629b85487edefc,
  TitleD0a25b4525784b0aB42541f4bcbd947a,
  SecondaryMarketingHeader701a21354900449a93c4Decdba9c310a,
  HomePageHero3583b7061f7444e483edB61f66eb0c1e,
  Title75d8e4575495444cA028F118b538c153,
  ContentSlug4caf7e5844f442449d522e16f7c8d923
}

export default Components;